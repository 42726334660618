import React from "react"
// import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Footer from "../components/Footer"
import HeaderLine from "../components/HeaderLine"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

const about = ({ pageContext, location }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  // Example of dynamically using location prop as a crumbLabel
  // NOTE: this code will not work for every use case, and is only an example
  const customCrumbLabel = location.pathname.toLowerCase().replace("-", " ")

  return (
    <Layout>
      <HeaderLine />

      <div className="container mx-auto max-w-screen-xl py-8 pb-2 container-pad">
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" / "
          crumbLabel={customCrumbLabel}
        />
      </div>

      <div className="container mx-auto max-w-screen-xl flex-none text-center text-3xl sm:text-5xl leading-none py-4 pb-8 container-pad">
        О КОМПАНИИ
      </div>

      <div className="container mx-auto max-w-screen-xl py-2 pb-8 container-pad">
        <p className="py-3 leading-5">
          Если работу нужно сделать, ее стоит сделать хорошо. Кроме того, что бы
          просто выполнить работу по договору, мы стараемся ее сделать так что
          бы на результат труда было приятно смотреть и не стыдно показать.
          Качество работы, по нашему мнению, зависит не только от отношения к
          работе, но и от наличия качественного и современного инструмента,
          именно поэтому, считаем необходимым инвестировать в средства
          производства. И это то, что позволяет нам быть быстрее конкурентов в
          своей работе и выполнять работу качественно!
        </p>
      </div>
      <Footer />
    </Layout>
  )
}

export default about
